import { isPlatformBrowser } from '@angular/common';

let plotlyInstance: any = null;

export function loadPlotly(platformId: Object): Promise<any> {
  if (!isPlatformBrowser(platformId)) {
    return Promise.reject('Plotly cannot be loaded on the server.');
  }

  // Return the already loaded instance
  if (plotlyInstance) {
    return Promise.resolve(plotlyInstance);
  }

  // Dynamically import Plotly.js and handle the module resolution
  return import('plotly.js-dist-min')
    .then((module: any) => {
      const Plotly = module.default || module; // Use default export if present
      plotlyInstance = Plotly;
      (window as any).Plotly = Plotly; // Optional: assign globally for debugging
      return Plotly;
    })
    .catch((error) => {
      console.error('Error importing Plotly:', error);
      return Promise.reject('Plotly failed to load.');
    });
}
