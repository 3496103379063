import { Component, ViewChild, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableComponent, PowerData } from '../table/table.component';
import { GraphComponent } from '../graph/graph.component';
import { DataService } from '../../services/data.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'sample';
  filters: { startDate: Date | null; endDate: Date | null; facility: string | null } | null = null;
  savedData: PowerData[] = [];
  isDataSaved: boolean = false;
  isBrowser: boolean;
  isLoggedIn: boolean = false;
  isInitializing: boolean = true;

  @ViewChild(TableComponent) tableComponent!: TableComponent;
  @ViewChild(GraphComponent) graphComponent!: GraphComponent;

  constructor(
    private snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: object,
    private dataService: DataService,
    private authService: MsalService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.initializeApp();
    }

    this.authService.handleRedirectObservable().subscribe({
      next: (response) => {
        if (response) {
          console.log('Authentication response:', response);
        }
        this.checkLoginState();
      },
      error: (error) => console.error('MSAL error:', error)
    });

    this.authService.instance.addEventCallback((event) => {
      if (event.eventType === 'msal:loginSuccess') {
        console.log('Login successful');
        this.checkLoginState();
      } else if (event.eventType === 'msal:logoutSuccess') {
        console.log('Logout successful');
        this.onLogout();
      }
    });
  }

  private initializeApp() {
    this.checkLoginState();
    setTimeout(() => {
      this.isInitializing = false;
    }, 0);
  }

  private checkLoginState() {
    if (!this.isBrowser) return;

    const accounts = this.authService.instance.getAllAccounts();
    this.isLoggedIn = accounts.length > 0;

    console.log(`User is logged in: ${this.isLoggedIn}`);
  }

  private onLogout() {
    this.isLoggedIn = false;
    console.log('User has logged out');
  }

  onFiltersChanged(filters: { startDate: Date | null; endDate: Date | null; facility: string | null }): void {
    this.filters = filters;

    // Default to Salinas River if no facility is selected
    if (!this.filters.facility) {
      this.filters.facility = 'Salinas River';
    }

    if (this.graphComponent) {
      this.graphComponent.applyFilters(this.filters);
    }
  }

  onTableDataChanged(): void {
    console.log('Table data has changed.');
    this.isDataSaved = false; // Mark data as unsaved since it has changed
  }

  onSave(): void {
    if (this.isBrowser) {
      const editedRecords = this.tableComponent.getEditedRecords();
      if (editedRecords.length === 0) {
        this.snackBar.open('No changes to save.', 'Close', { duration: 2000 });
        return;
      }

      this.dataService.saveEditedRecords(editedRecords).subscribe(
        () => {
          this.snackBar.open('Changes saved successfully!', 'Close', { duration: 2000 });
          this.isDataSaved = true;
        },
        (error) => {
          console.error('Error saving changes:', error);
          this.snackBar.open(`Error saving changes: ${error.error.detail}`, 'Close', { duration: 2000 });
        }
      );
    }
  }

  onExport(): void {
    if (this.isBrowser) {
      const tableData = this.tableComponent.getTableData();
      if (tableData.length === 0) {
        this.snackBar.open('No data to export.', 'Close', { duration: 2000 });
        return;
      }
      const csvData = this.jsonToCsv(tableData);
      this.exportCsv(csvData, 'table_data.csv');
    }
  }

  private jsonToCsv(data: any[]): string {
    if (data.length === 0) {
      return '';
    }

    const headers = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(row => {
      csvRows.push(headers.map(header => `"${row[header]}"`).join(','));
    });

    return csvRows.join('\n');
  }

  private exportCsv(csvData: string, filename: string): void {
    if (this.isBrowser) {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }
}
