import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLoggedIn: boolean = false;

  constructor(private authService: MsalService) {}

  ngOnInit() {
    // Check if user is already logged in
    this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    console.log('User is logged in:', this.isLoggedIn);
  }

  login() {
    this.authService
      .loginPopup({
        scopes: ['User.Read'],
      })
      .subscribe({
        next: (response) => {
          console.log("Logged in successfully",response)
          // this.isLoggedInEmit.emit(true);
          this.isLoggedIn = true
        },
        error: (err) => {
          console.error('Login failed:', err);
          // this.isLoggedInEmit.emit(false);
          this.isLoggedIn = false
        },
      });
  }

  public logout() {
    this.authService.logoutRedirect();
  }
}