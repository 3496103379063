<!-- Loading Screen During Initialization -->
<div *ngIf="isInitializing" class="loading-container">
  <p>Loading, please wait...</p> <!-- Optional spinner or loader -->
</div>

<!-- Main Content -->
<div *ngIf="!isInitializing">
  <div *ngIf="isLoggedIn" class="page-container">
    <!-- Header Section -->
    <div class="header">
      <div style="display: flex; flex-direction: row; justify-content: end; gap: 28%;">
        <div class="head">
          <div class="logo-container">
            <img [width]="120" height="60" src="../../assets/img/Chevron-Logo.png" alt="Chevron Logo" />
          </div>
          <div class="text-container">
            <h1 class="title">Cogen Power Predictor</h1>
          </div>
        </div>
        <div style="display: flex; flex: 0;">
          <app-logout style="margin-right: 10px; margin-top: 14px;"></app-logout>
        </div>
      </div>
      <div class="navbar-container">
        <mat-toolbar color="primary"></mat-toolbar>
      </div>
      <div class="filter-container">
        <app-filter (filtersChanged)="onFiltersChanged($event)" (save)="onSave()" (export)="onExport()"></app-filter>
      </div>
    </div>

    <!-- Table and Graph -->
    <div class="scrollable-content">
      <app-table [filters]="filters" (dataChanged)="onTableDataChanged()"></app-table>
      <app-graph></app-graph>
    </div>
  </div>

  <!-- Login Section -->
  <div *ngIf="!isLoggedIn">
    <app-login></app-login>
  </div>
</div>
