import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '../../services/data.service';
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { FormRecord } from '@angular/forms';

export interface PowerData {
  timestamp: string;
  temp: number;
  humidity: number;
  modeledPowerPrediction_True?: number;
  modeledPowerPrediction_False?: number;
  facility: string;
  unitDown: boolean | null;
  evapToggle: boolean | null;
  fieldLoad: number | null;
  reviewedPowerPrediction: number | null;
  comments: string | null;
  edited?: boolean;
  userEdited?: boolean;  
  originalReviewedPower?: number;
}

interface DateGroup {
  date: string;
  records: PowerData[];
  unitDown: boolean;
  evapToggle: boolean;
  fieldLoad: number | null;
}
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})
export class TableComponent implements OnChanges {
  displayedColumns: string[] = [
    'timestamp', 'temp', 'humidity', 'modeledPowerPrediction', 'unitDown', 
    'evapToggle', 'fieldLoad', 'reviewedPowerPrediction', 'comments'
  ];

  groupedByDate: DateGroup[] = [];
  dataSource: PowerData[] = [];
  originalData: PowerData[] = [];
  isDataAvailable: boolean = true;
  private previousFieldLoadValue: number | null = null;

  @Input() filters: { startDate: Date | null; endDate: Date | null; facility: string | null } | null = null;
  @Output() dataChanged = new EventEmitter<void>();

  constructor(
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadInitialData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters'] && !changes['filters'].isFirstChange()) {
      this.applyFilters();
    }
  }

  public loadInitialData(): void {
    this.dataService.getData({ date: null, facility: null }).subscribe((data) => {
      console.log("Initail table data : ",data)
      this.originalData = this.mapToEvapData(data);
      this.groupDataByDate();
      this.dataSource = [...this.originalData];
      this.isDataAvailable = this.dataSource.length > 0;
    }, error => {
      console.error("Error fetching table data", error);
      this.isDataAvailable = false;
    });
  }
  
  public groupDataByDate(): void {
  const grouped = this.originalData.reduce((acc, record) => {
    const date = new Date(record.timestamp).toDateString();
    if (!acc[date]) {
      acc[date] = [];
    }

    // Ensure `reviewedPowerPrediction` is set to null if undefined
    acc[date].push({
      ...record,
      reviewedPowerPrediction: record.reviewedPowerPrediction ?? null
    });

    return acc;
  }, {} as { [date: string]: PowerData[] });

  this.groupedByDate = Object.keys(grouped).map(date => ({
    date,
    records: grouped[date],
    unitDown: false,
    evapToggle: false,
    fieldLoad: null,
  }));
  }

  public onUnitDownForDate(group: DateGroup, toggle: boolean): void {
    group.records.forEach((record) => {
        if (toggle) {  
            record.unitDown = true;
            record.reviewedPowerPrediction = 0;
            record.fieldLoad = 0;
        } else {
            record.unitDown = false;  
            record.reviewedPowerPrediction = (record.evapToggle  ? record.modeledPowerPrediction_True : record.modeledPowerPrediction_False) ?? null; 
        }
        record.edited = true;  
    });
    this.dataSource = this.dataSource.map(record => {
      const isSameDate = new Date(record.timestamp).toDateString() === group.date;
      return isSameDate ? { ...record, ...group.records.find(r => r.timestamp === record.timestamp) } : record;
    });
  }
  
  public onEvapToggleForDate(dateGroup: DateGroup, toggle: boolean): void {
      dateGroup.evapToggle = toggle;  // Set the global evapToggle state
    
      dateGroup.records.forEach(record => {
        record.evapToggle = toggle;
    
        // Update reviewedPowerPrediction only if userEdited is false
        if (!record.userEdited) {
          this.updateReviewedPowerPrediction(record);
        }
      });
    
      // Reflect updates in dataSource for consistency
      this.dataSource = this.dataSource.map(record => {
        const isSameDate = new Date(record.timestamp).toDateString() === dateGroup.date;
        return isSameDate ? { ...record, ...dateGroup.records.find(r => r.timestamp === record.timestamp) } : record;
      });
    
      this.cd.markForCheck();
      this.cd.detectChanges();
  }

// When fieldLoad changes for a date group, update reviewedPowerPrediction for each record
  public onFieldLoadForDate(dateGroup: DateGroup, event: any): void {
  const inputValue = parseFloat(event.target.value);
  if (isNaN(inputValue) || inputValue < 0) {
    this.snackBar.open('Please enter a valid value for Field Load (no negative values allowed).', 'Close', { duration: 3000 });
    return;
  }

  // Apply fieldLoad and recalculate reviewed power if userEdited is false
  dateGroup.records.forEach(record => {
    if (!record.unitDown) {
      record.fieldLoad = inputValue;
      if (!record.userEdited) {
        this.updateReviewedPowerPrediction(record);
      }
      record.edited = true;
    }
  });

  // Reflect updates in dataSource for consistency
  this.dataSource = this.dataSource.map(record => {
    const isSameDate = new Date(record.timestamp).toDateString() === dateGroup.date;
    return isSameDate ? { ...record, ...dateGroup.records.find(r => r.timestamp === record.timestamp) } : record;
  });

  this.cd.markForCheck();
  this.cd.detectChanges();
  }
  

  public mapToEvapData(data: any[]): PowerData[] {
    return data.map(item => {
      const defaultModeledPower = item.modeledPowerPrediction_False ?? item.modeledPowerPrediction_True ?? null;
      const reviewedPowerPrediction = item.reviewedPowerPrediction ?? defaultModeledPower;
  
      return {
        timestamp: item.timestamp,
        temp: item.temp,
        humidity: item.humidity,
        modeledPowerPrediction_True: item.modeledPowerPrediction_True,
        modeledPowerPrediction_False: item.modeledPowerPrediction_False,
        facility: item.facility,
        unitDown: item.unitDown ?? false,
        evapToggle: item.evapToggle ?? false,  // Default to false
        fieldLoad: item.fieldLoad ?? null,
        reviewedPowerPrediction: reviewedPowerPrediction,  // Set default reviewed power
        comments: item.comments ?? null,
        edited: false,
        userEdited: false  // Default user-edited status to false
      };
    });
  }
  
  public onUnitDownChange(element: PowerData, dateGroup: DateGroup, event: Event): void {
    const checked = (event.target as HTMLInputElement).checked; 
    element.unitDown = checked; 
    if (checked) {
        element.reviewedPowerPrediction = 0;
        element.fieldLoad = 0;
    } else {
        element.reviewedPowerPrediction = (element.evapToggle ? element.modeledPowerPrediction_True : element.modeledPowerPrediction_False) ?? null;
    }
    element.edited = true;
    const allUnitsDown = dateGroup.records.every(record => record.unitDown);
    dateGroup.unitDown = allUnitsDown;
    const dataSourceIndex = this.dataSource.findIndex(record => record.timestamp === element.timestamp && record.facility === element.facility);
    if (dataSourceIndex !== -1) {
        this.dataSource[dataSourceIndex] = { ...element }; 
    }
  }

  public onEvapToggleChange(element: PowerData, dateGroup: DateGroup, event: Event): void {
  const checked = (event.target as HTMLInputElement).checked;
  element.evapToggle = checked;

  // Only recalculate if userEdited is false
  if (!element.userEdited) {
    this.updateReviewedPowerPrediction(element);
  }
  element.edited = true;

  // Update the group's evapToggle state if all records match
  const allRecordsToggled = dateGroup.records.every(record => record.evapToggle);
  dateGroup.evapToggle = allRecordsToggled;
  }

  private updateReviewedPowerPrediction(record: PowerData): void {
  if (record.unitDown) {
    // If unitDown is true, set reviewed power to 0
    record.reviewedPowerPrediction = 0;
  } else if (record.userEdited) {
    // Retain the manually set reviewedPowerPrediction if userEdited is true
    console.log("User edited the reviewed power; retaining value:", record.reviewedPowerPrediction);
    return;
  } else {
    // Calculate reviewed power based on evapToggle and fieldLoad if userEdited is false
    const modelPrediction = record.evapToggle 
      ? record.modeledPowerPrediction_True 
      : record.modeledPowerPrediction_False;

    // Calculate reviewedPowerPrediction if modelPrediction and fieldLoad are defined
    record.reviewedPowerPrediction = 
      modelPrediction !== undefined && record.fieldLoad !== null && record.fieldLoad !== undefined
        ? parseFloat((modelPrediction - record.fieldLoad).toFixed(3))
        : modelPrediction ?? null;
    console.log("Calculated reviewedPowerPrediction:", record.reviewedPowerPrediction);
  }
  }

  
  public onFieldChange(element: PowerData, field: string, newValue: any): void {
  if (field === 'comments') {
    element.comments = typeof newValue === 'string' ? newValue.trim() : null;
  } else if (field === 'reviewedPowerPrediction') {
    const newValueAsNumber = parseFloat(newValue);
    if (!isNaN(newValueAsNumber)) {
      element.reviewedPowerPrediction = element.unitDown ? 0 : newValueAsNumber;
      element.userEdited = true; 
       // Flag that this value was manually edited
       console.log("Manually updating Reviewed Power Prediction for Record:", element.timestamp, "New Value:", newValueAsNumber);
    }
  }
  element.edited = true;
  this.updateRecordInDataSource(element);
  this.updateRecordInOriginalData(element);
  this.emitDataChanged();
  this.cd.markForCheck();
  this.cd.detectChanges();
  }

  private updateRecordInOriginalData(record: PowerData): void {
    const originalIndex = this.originalData.findIndex(
      item => item.timestamp === record.timestamp && item.facility === record.facility
    );
    if (originalIndex !== -1) {
      this.originalData[originalIndex] = { ...this.originalData[originalIndex], ...record };
    }
  }
  
  private updateRecordInDataSource(record: PowerData): void {
    const dataSourceIndex = this.dataSource.findIndex(
      item => item.timestamp === record.timestamp && item.facility === record.facility
    );
    if (dataSourceIndex !== -1) {
      this.dataSource[dataSourceIndex] = { ...this.dataSource[dataSourceIndex], ...record };
    }
  }
  
  public onFieldLoadFocus(element: PowerData): void {
    this.previousFieldLoadValue = element.fieldLoad;  
  }

  public applyFilters(): void {
    if (this.filters) {
      const { startDate, endDate, facility } = this.filters;
      const allRecords = this.originalData.filter(item => {
        const itemDate = new Date(item.timestamp);
        const isDateInRange = startDate && endDate ? this.isDateInRange(itemDate, startDate, endDate) : true;
        const isFacilityMatch = facility ? item.facility === facility : true;
        return isDateInRange && isFacilityMatch;
      });
      const grouped = allRecords.reduce((acc, record) => {
        const date = new Date(record.timestamp).toDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(record);
        return acc;
      }, {} as { [date: string]: PowerData[] });
      this.groupedByDate = Object.keys(grouped).map(date => ({
        date,
        records: grouped[date].map(record => ({ ...record, visible: true })),
        unitDown: false,
        evapToggle: false,
        fieldLoad: null,
      }));
      this.isDataAvailable = allRecords.length > 0; // Update flag based on filtered data
    } else {
      this.groupDataByDate();
      this.isDataAvailable = this.originalData.length > 0; // Update flag based on unfiltered data
    }
    this.emitDataChanged();
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  public isDateInRange(date: Date, startDate: Date, endDate: Date): boolean {
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999);
    return date >= start && date <= end;
  }

  private emitDataChanged(): void {
    this.dataChanged.emit();
  }

  getEditedRecords(): Partial<PowerData>[] {
    return this.dataSource.filter(record => record.edited).map(record => ({
      timestamp: record.timestamp,
      facility: record.facility,
      fieldLoad: record.fieldLoad,
      reviewedPowerPrediction: record.reviewedPowerPrediction,
      comments: record.comments,
      evapToggle: record.evapToggle,
      unitDown: record.unitDown
    }));
  }

  getTableData(): PowerData[] {
    return this.dataSource;
  }
}