<div class="toggle-container">
  <label>Evap Condition:</label>
  <mat-button-toggle-group [(ngModel)]="evapCondition" (change)="onEvapConditionChange($event)">
    <mat-button-toggle value="false">False</mat-button-toggle>
    <mat-button-toggle value="true">True</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="graph-container">
  <!-- Show "No data available" message when no data is available -->
  <div *ngIf="!isDataAvailable" class="no-data-message">
    No data available for graphs in the current facility.
  </div>

  <!-- Placeholder for Graph 1 -->
  <div *ngIf="isDataAvailable" id="plotly-graph1"></div>
  
  <!-- Placeholder for Graph 2 -->
  <div *ngIf="isDataAvailable" id="plotly-graph2"></div>
</div>
