<div class="filter-container1">
    <div class="filter-controls">
      <!-- Date Range Picker -->
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [(ngModel)]="selectedStartDate" placeholder="Start date" (ngModelChange)="onDateRangeChange()">
          <input matEndDate [(ngModel)]="selectedEndDate" placeholder="End date" (ngModelChange)="onDateRangeChange()">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
  
      <!-- Facility Filter -->
      <mat-form-field appearance="fill">
        <mat-label>Facility</mat-label>
        <mat-select [(ngModel)]="selectedFacility" (ngModelChange)="onFacilityChange()">
          <mat-option *ngFor="let facility of facilities" [value]="facility">
            {{ facility }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <button mat-button (click)="clearFilters()">Clear Filters</button>
    </div>
  
    <div class="action-buttons">
      <button mat-button (click)="onSave()" class="save-button">Save</button>
      <button mat-button (click)="onExport()" class="export-button">Export</button> <!-- Trigger event instead of logic -->
    </div>
  </div>