import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, map, catchError } from 'rxjs';
import { environment } from '../environments/environments';
import { throwError } from 'rxjs';
import { filter } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private baseUrl = environment.WebApiUrl;

  private get apiUrl() {
    return `${this.baseUrl}/table`;
  }

  private get graphApiUrl() {
    return `${this.baseUrl}/graph`;
  }

  private get graph1ApiUrl() {
    return `${this.graphApiUrl}/graph1`;
  }

  private get graph2ApiUrl() {
    return `${this.graphApiUrl}/graph2`;
  }

  constructor(private http: HttpClient) {}

  // Helper method to get cache-busting headers
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Access-Control-Allow-Origin':'*',
      'Authorization':'authKey'
    });
  }

  // Method to fetch data with cache-busting headers and error handling
  getData(filters: { date: Date | null; facility: string | null }): Observable<any[]> {
    let params = new HttpParams();
    console.log("filters ? :",filters,filters?.date)
    if (filters?.date) {
      params = params.append('date', filters.date.toISOString());
    }
    if (filters?.facility) {
      params = params.append('facility', filters.facility);
    }
    params = params.append('timestamp', new Date().getTime().toString());
    console.log("Checking the value in service : params :", params)

    const headers = this.getHeaders();
    return this.http.get<any[]>(`${this.apiUrl}/excel-data`, { params, headers, observe: 'response' }).pipe(
      map(response => {
        // Properly log response headers
        const headerKeys = response.headers.keys();
        headerKeys.forEach(key => {
          console.log(`${key}: ${response.headers.get(key)}`);
        });
        console.log('Response Headers: response >:',response,"\n response : body :",response.body, "\n response : status :",response.status);

        // Handle the case where response.body might be null
        return response.body ?? [];
      }),
      catchError(error => {
        console.error('Error fetching data:', error);
        return throwError(() => new Error('Error fetching data from server.'));
      })
    );
  }

  // Method to save edited records with error handling
  saveEditedRecords(records: any[]): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(`${this.apiUrl}/save-edited-records`, records, { headers }).pipe(
      catchError(error => {
        console.error('Error saving records:', error);
        return throwError(() => new Error('Error saving records.'));
      })
    );
  }

  // Method to fetch Graph 1 data with error handling
  getGraph1Data(evapCondition: string, filters: { startDate: Date | null; endDate: Date | null; facility: string | null }): Observable<any> {
    let params = new HttpParams();
    if (filters.startDate) {
      const formattedStartDate = this.formatDate(filters.startDate, 'start');
      params = params.append('start_date', formattedStartDate);
    }
    if (filters.endDate) {
      const formattedEndDate = this.formatDate(filters.endDate, 'end');
      params = params.append('end_date', formattedEndDate);
    }
    if (filters.facility) {
      params = params.append('facility', filters.facility);
    }

    const headers = this.getHeaders();
    return this.http.get<any>(`${this.graph1ApiUrl}/${evapCondition}`, { params, headers }).pipe(
      catchError(error => {
        console.error('Error fetching Graph 1 data:', error);
        return throwError(() => new Error('Error fetching Graph 1 data.'));
      })
    );
  }

  // Method to fetch Graph 2 data with error handling
  getGraph2Data(filters: { startDate: Date | null; endDate: Date | null; facility: string | null }): Observable<any> {
    let params = new HttpParams();
    if (filters.startDate) {
      const formattedStartDate = this.formatDate(filters.startDate, 'start');
      params = params.append('start_date', formattedStartDate);
    }
    if (filters.endDate) {
      const formattedEndDate = this.formatDate(filters.endDate, 'end');
      params = params.append('end_date', formattedEndDate);
    }
    if (filters.facility) {
      params = params.append('facility', filters.facility);
    }

    const headers = this.getHeaders();
    return this.http.get<any>(`${this.graph2ApiUrl}`, { params, headers }).pipe(
      catchError(error => {
        console.error('Error fetching Graph 2 data:', error);
        return throwError(() => new Error('Error fetching Graph 2 data.'));
      })
    );
  }

  // Utility method to format dates without time zone conversion
  private formatDate(date: Date, type: 'start' | 'end'): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return type === 'start' ? `${year}-${month}-${day} 00:00:00` : `${year}-${month}-${day} 23:59:59`;
  }
}
