<div class="table-container">
  <table class="custom-table">
    <thead>
      <tr>
        <th>Timestamp</th>
        <th>Temperature</th>
        <th>Humidity</th>
        <th>Modeled Power</th>
        <th>Unit Down</th>
        <th>Evap Toggle</th>
        <th>Field Load</th>
        <th>Reviewed Power</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <!-- If no data is available, show a single row with a placeholder message -->
      <tr *ngIf="!isDataAvailable">
        <td colspan="9" class="no-data-row">No data available for the current facility.</td>
      </tr>

      <!-- Loop through each date group when data is available -->
      <ng-container *ngIf="isDataAvailable">
        <ng-container *ngFor="let dateGroup of groupedByDate">
          <tr class="sub-header">
            <td>{{ dateGroup.date | date: 'yyyy-MM-dd' }}</td>
            <td colspan="2"></td>
            <td colspan="1"></td>
            <td>
              <input 
                  type="checkbox" 
                  [(ngModel)]="dateGroup.unitDown" 
                  (change)="onUnitDownForDate(dateGroup, dateGroup.unitDown)" 
              />
            </td>
            <td>
              <input 
                type="checkbox" 
                [(ngModel)]="dateGroup.evapToggle" 
                (change)="onEvapToggleForDate(dateGroup, dateGroup.evapToggle)" 
                [disabled]="dateGroup.unitDown" 
              />
            </td>
            <td>
              <input
                type="number"
                [(ngModel)]="dateGroup.fieldLoad"
                (change)="onFieldLoadForDate(dateGroup, $event)"
                [disabled]="dateGroup.unitDown" 
                placeholder="Enter Field Load"
              />
            </td>
            <td colspan="2"></td>
          </tr>

          <!-- Loop through each record in the date group -->
          <tr *ngFor="let element of dateGroup.records">
            <td>{{ element.timestamp }}</td>
            <td>{{ element.temp }}</td>
            <td>{{ element.humidity }}</td>
            <td>{{ element.evapToggle ? element.modeledPowerPrediction_True : element.modeledPowerPrediction_False }}</td>

            <td>
              <input 
                type="checkbox" 
                [(ngModel)]="element.unitDown" 
                (change)="onUnitDownChange(element, dateGroup, $event)" 
              />
            </td>
            <td>
              <input 
                type="checkbox" 
                [(ngModel)]="element.evapToggle"  
                (change)="onEvapToggleChange(element, dateGroup, $event)" 
                [disabled]="element.unitDown ?? false" 
              />
            </td>
            <td>
              <input
                type="number"
                [ngModel]="element.fieldLoad"
                (focus)="onFieldLoadFocus(element)"
                [disabled]="true" 
                placeholder="Auto-filled"
              />
            </td>
            <td>
              <input
                type="number"
                [(ngModel)]="element.reviewedPowerPrediction"
                [disabled]="element.unitDown ?? false" 
                (ngModelChange)="onFieldChange(element, 'reviewedPowerPrediction', $event)"
              />
            </td>
            <td>
              <input
                type="text"
                [(ngModel)]="element.comments"
                (ngModelChange)="onFieldChange(element, 'comments', $event)"
              />
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
