import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '../../services/data.service';
import { loadPlotly } from '../../class/plotly-loader';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
})
export class GraphComponent implements OnInit {
  isBrowser: boolean;
  evapCondition: string = 'false';
  isDataAvailable: boolean = true; // New flag to track graph data availability
  currentFilters: { startDate: Date | null; endDate: Date | null; facility: string | null } = {
    startDate: null,
    endDate: null,
    facility: null,
  };

  constructor(
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.fetchGraphs(); // Load initial data with default filters
    }
  }

  onEvapConditionChange(event: any): void {
    this.evapCondition = event.value;
    console.log(`Evaporation condition changed to: ${this.evapCondition}`);

    // Manually trigger change detection
    this.cdr.detectChanges();

    // Re-fetch the graphs with the updated evapCondition and current filters
    this.fetchGraphs(this.currentFilters);
  }

  applyFilters(filters: { startDate: Date | null; endDate: Date | null; facility: string | null }): void {
    this.currentFilters = { ...filters };

    // Log the selected facility for debugging
    console.log(`Selected facility: ${this.currentFilters.facility}`);

    // Clear graphs for unsupported facilities
    if (this.currentFilters.facility && ['Kern River', 'Sycamore'].includes(this.currentFilters.facility)) {
      console.log(`Clearing graphs for unsupported facility: ${this.currentFilters.facility}`);
      this.isDataAvailable = false; // No data available for unsupported facilities
      this.clearGraphs();
      return;
    }

    // Fetch graphs for supported facilities
    this.fetchGraphs(this.currentFilters);
  }

  clearFilters(): void {
    console.log('Clearing filters and resetting to default.');
    this.currentFilters = { startDate: null, endDate: null, facility: 'Salinas River' }; // Reset to default facility
    this.fetchGraphs();
  }

  fetchGraphs(filters: { startDate: Date | null; endDate: Date | null; facility: string | null } = this.currentFilters): void {
    this.isDataAvailable = true; // Reset availability before fetching data
    let graph1Available = false;
    let graph2Available = false;

    // Fetch Graph 1
    this.fetchGraph1(filters).then((dataAvailable) => {
      graph1Available = dataAvailable;
      this.updateDataAvailability(graph1Available, graph2Available);
    });

    // Fetch Graph 2
    this.fetchGraph2(filters).then((dataAvailable) => {
      graph2Available = dataAvailable;
      this.updateDataAvailability(graph1Available, graph2Available);
    });
  }

  fetchGraph1(filters: { startDate: Date | null; endDate: Date | null; facility: string | null }): Promise<boolean> {
    return new Promise((resolve) => {
      this.dataService.getGraph1Data(this.evapCondition, filters).subscribe(
        (response) => {
          console.log('Response received for Graph 1:', response);
          this.renderGraph('plotly-graph1', response.graph1);
          resolve(true);
        },
        (error: unknown) => {
          console.error('Error fetching Graph 1:', error);
          this.clearGraphPlaceholder('plotly-graph1');
          resolve(false);
        }
      );
    });
  }

  fetchGraph2(filters: { startDate: Date | null; endDate: Date | null; facility: string | null }): Promise<boolean> {
    return new Promise((resolve) => {
      this.dataService.getGraph2Data(filters).subscribe(
        (response) => {
          console.log('Response received for Graph 2:', response);
          this.renderGraph('plotly-graph2', response.graph2);
          resolve(true);
        },
        (error: unknown) => {
          console.error('Error fetching Graph 2:', error);
          this.clearGraphPlaceholder('plotly-graph2');
          resolve(false);
        }
      );
    });
  }

  renderGraph(elementId: string, graphData: any): void {
    if (!this.isBrowser) return;

    loadPlotly(this.platformId)
      .then((Plotly) => {
        if (!Plotly || typeof Plotly.newPlot !== 'function') {
          console.error('Plotly instance is not correctly loaded. Check the import.');
          return;
        }

        const graphElement = document.getElementById(elementId);
        if (!graphElement) {
          console.error(`Graph element with id ${elementId} not found.`);
          return;
        }

        try {
          Plotly.newPlot(graphElement, graphData.data, graphData.layout)
            .then(() => console.log('Graph rendered successfully'))
            .catch((error: Error) => {
              console.error('Error during Plotly.newPlot:', error);
            });
        } catch (error: unknown) {
          console.error('Unexpected error while plotting:', error);
        }
      })
      .catch((error: unknown) => {
        console.error('Error loading Plotly:', error);
      });
  }

  clearGraphs(): void {
    // Remove graph elements from the DOM
    this.clearGraphPlaceholder('plotly-graph1');
    this.clearGraphPlaceholder('plotly-graph2');
  }

  clearGraphPlaceholder(elementId: string): void {
    const graphElement = document.getElementById(elementId);
    if (graphElement) {
      graphElement.innerHTML = ''; // Clear content
    }
  }

  updateDataAvailability(graph1Available: boolean, graph2Available: boolean): void {
    this.isDataAvailable = graph1Available || graph2Available; // Set flag based on availability of any graph
    this.cdr.detectChanges();
  }
}
