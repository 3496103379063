export const environmentRoot = {
  timeStamp: new Date().toString(),
  popupForLogin: false,
  cacheLocation: 'localStorage',
  instance: 'https://login.microsoftonline.com/',
  tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
  clientId: '7b64d6ab-a72a-44bb-8b30-13603c604553',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  graphScopes: ['User.Read.All'],
  apiScope: ['https://cogenp-test.azure.chevron.com/user_impersonation'],
  redirectUri: 'http://localhost:4200',
  accessGroupId:'Surface-FMO',
  productOwnerEmail:'JustineArnold@chevron.com',
  version: '2.0.0',
}