import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent {
  facilities = ['Salinas River', 'Kern River', 'Sycamore'];

  selectedStartDate: Date | null = null;  // Start date for date range
  selectedEndDate: Date | null = null;    // End date for date range
  selectedFacility: string | null = null;

  @Output() filtersChanged = new EventEmitter<{ startDate: Date | null; endDate: Date | null; facility: string | null }>();
  @Output() save = new EventEmitter<void>();  // Emit save event to parent
  @Output() export = new EventEmitter<void>();  // Emit export event to parent

  clearFilters() {
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedFacility = null;
    this.emitFiltersChanged();
  }

  onDateRangeChange() {
    this.emitFiltersChanged();
  }

  // onFacilityChange() {
  //   this.emitFiltersChanged();
  // }

  private emitFiltersChanged() {
    this.filtersChanged.emit({
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
      facility: this.selectedFacility
    });
  }
  onFacilityChange() {
    if (!this.selectedFacility) {
      // Default to Salinas River if no facility is selected
      this.selectedFacility = 'Salinas River';
    }
    this.emitFiltersChanged();
  }

  onSave() {
    this.save.emit();  // Trigger save event to AppComponent
  }

  onExport() {
    this.export.emit();  // Trigger export event to AppComponent
  }
}